import React, { useContext, useEffect, useRef, useState } from "react";
import UserContext from "../Contexts/UserContext.ts";
import * as ExcelJs from "exceljs";
import Button from "@mui/material/Button";
import { Col, Row } from "react-bootstrap";
import ExcelFileDisplay from "./ExcelFileDisplay.tsx";

export function ExcelBulkDataUpload() {

    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const userinfo = useContext(UserContext);
    const [firstRow, setFirstRow] = useState<string[]>([]);
    const [isValidExcel, setIsVakidExcel] = useState(false);
    const [getfiles, setgetfiles] = useState(false);
    const fileinput = useRef();

    useEffect(() => {
        setTimeout(() => {
            setSuccess('');
        }, 10000);
    }, [success]);

    useEffect(() => {
        setTimeout(() => {
            setError('');
        }, 3000);
    }, [error]);

    const handleFileChange = async (e) => {
        setFile(e.target.files[0]);

        if (!e.target.files[0]) { setError('Please select a file to upload.'); return; }
        if (!validateFile(e.target.files[0])) {
            setIsVakidExcel(false);
            return;
        }
        // await validateExcelHeaderColumn(e.target.files[0]);

        const workbook = new ExcelJs.Workbook();
        const reader = new FileReader();
        var rowData = null;
      reader.onload = async (e) => {
            const buffer = e.target.result;
            if (buffer === undefined || buffer === null) { return excelFileError("buffer is null or undefined."); }
            await workbook.xlsx.load(buffer);
            const worksheet = workbook.getWorksheet("Issues");
            // const worksheet = workbook.worksheets[0];
            if (worksheet === undefined) {
                return excelFileError("worksheet is undefined.");
            }
            let hasData = false;
    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      if (rowNumber > 1) { // Skip the first row
        hasData = true;
        return false; // Break the loop
      }
    });

    if(!hasData){
        return excelFileError("worksheet has no data.");
    }
            const row = worksheet.getRow(1);
            if (row === undefined || row === null|| row?.values === null || row?.values === undefined) {
                return excelFileError("row or row values are null.");
            }
            setFirstRow(row.values.slice(1));
           var isValidExcel =  CompareArray(row.values.slice(1));
        setIsVakidExcel(isValidExcel);
        
        setMissingCol(""); 
        }
        reader.readAsArrayBuffer(e.target.files[0]);
    };

    const validateFile = (file) => {
        const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'application/vnd.ms-excel.sheet.macroEnabled.12', 'application/vnd.ms-excel.sheet.macroenabled.12'];
        const maxSize = 2 * 1024 * 1024;
        // 2MB
        if (!validTypes.includes(file.type)) {
            setError('Invalid file type. Only Excel files are allowed.');
            return false;
        }

        if (file.size > maxSize) {
            setError('File size exceeds 2MB limit.');
            return false;
        }
        return true;
    };

    const excelFileError = (message) => {
        ResetFileInput();
        setError("template mismatch, please upload correct template. "+message);
        return false;
    }

    const CompareArray = (rowData) => {
        var dd = firstRow;
        if (rowData == null) { return excelFileError("rowdata is null"); }

        const excelHeaders = ["#No", "IssueType", "Region", "Jurisdictions", "State/Province", "County/District", "City/Muncipalities", "Subject", "EnforcementDate",
            "EnforcementDetails", "Action", "AdditionalURL", "URL", "Status", "OpentoCustomer", "Duedate", "LegislativeStatus", "Keyword", "Customer", "ActionDetails", "Category",
            "ImpactforServiceLine", "CreatedDate", "BusinessUnit",];
        var missingCols = "";
        for (let i = 0; i < excelHeaders.length; i++) {

            if (!rowData?.includes(excelHeaders[i])) {
                missingCols = missingCols + ", " + excelHeaders[i];
            }
        }

        return missingCols.length > 0 ? excelFileError("missing columns.") : true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isValidExcel) {
            setError("invalid file.");
            return;
        }

        const bearer = `Bearer ${userinfo.accessToken}`
        const formData = new FormData();
        formData.append('file', file);
        ResetFileInput();

        var headers = new Headers();
        headers.append("Authorization", bearer);

        const options = {
            method: "POST",
            headers: headers,
            ContentType: 'applmultipart/form-data',
            body: formData
        };

        try {
            const result = fetch(`${process.env.REACT_APP_Urlendpoint}ExcelFile/UploadExcelFile`, options).then(responce =>
                responce.json()
            ).then(data => {
                if (data.isSuccess) {
                    setgetfiles(!getfiles);
                    setSuccess("Note: File upload is complete. The uploaded data will appear in the Issue List tab shortly(approx. 5min).");
                }
                else {
                    setError(data.message);
                }
            });
        }
        catch (err) { setError('Failed to upload file.'); }
    };

    const ResetFileInput = () => {
        setFile(null);
        if (fileinput.current) {
            fileinput.current.value = null;
        }
        setIsVakidExcel(false);
    }


    const downloadFile = (filename) => {
        const bearer = `Bearer ${userinfo.accessToken}`;

        var headers = new Headers();
        headers.append("Authorization", bearer);

        fetch(`${process.env.REACT_APP_Urlendpoint}ExcelFile/Download`, { method: 'GET', headers:headers})
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);

                document.body.appendChild(link);
                link.click(); 
                // link.parentNode.removeChild(link);
            })
            .catch(err => console.error('Error downloading the file', err));
    }

    return (
        <>
            <div className="dataUploadContainer">
               <Col>
               <Row style={{ paddingRight: 20, paddingLeft: 20, paddingTop: 10 }}> 
                            <Col>
                            <h6 className="headerborderstyle">Upload</h6>
                            </Col>
                         </Row>

               <div className="file-upload">
                    <h5>Excel data - bulk upload</h5>
                    <br />
                    <br />
                    <form onSubmit={handleSubmit}>
                        <input type="file" ref={fileinput} onChange={handleFileChange} />
                        <Button type="submit" disabled={!isValidExcel}>Upload</Button>
                        <br />
                        <a onClick={() => downloadFile("DataUploadTemplate.xlsm")} className="mb-2 pointerCurser" style={{ fontSize: 10, margin: 10 }}>download template</a>
                    </form>
                    {error && <p className="error">{error}</p>}
                    {success && <p className="success">{success}</p>}
                </div>
                </Col>
                <Col style={{ marginLeft: 15, height: "inherit", borderLeftWidth: 0.5, borderLeftColor: 'gray', borderLeftStyle: "solid" }}>
                <Row style={{ paddingRight: 20, paddingLeft: 20, paddingTop: 10 }}> 
                            <Col>
                            <h6 className="headerborderstyle">History</h6>
                            </Col>
                         </Row>
                <ExcelFileDisplay getBlob={getfiles}/>
                </Col>
            </div>
        </>
    );
}
